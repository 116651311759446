import React, { useState } from 'react';
// import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import Layout from '../components/Layout';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { useTheme } from '@mui/material/styles';
import { Link } from 'gatsby';
import '../styles/NewPageStyles.css';
import englishImage from '../assets/en.svg';
import chinaImage from '../assets/ch.svg';
import espanoImage from '../assets/es.svg';
import portugeseImage from '../assets/po.svg';

const data = [
  {
    imagesList: [
      'maps/china_specific_south_up_detailed_english_(us).jpg',
      'maps/china_specific_south_up_detailed_english_(us).png',
      'maps/china_specific_south_up_detailed_english_(us).svg',
    ],
    imagePath: 'maps/china_specific_south_up_detailed_english_(us).png',
    fileName: 'china_specific_south_up_detailed_english_(us)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/china_specific_south_up_detailed_简体中文.jpg',
      'maps/china_specific_south_up_detailed_简体中文.png',
      'maps/china_specific_south_up_detailed_简体中文.svg',
    ],
    imagePath: 'maps/china_specific_south_up_detailed_简体中文.png',
    fileName: 'china_specific_south_up_detailed_简体中文',
    content:
      "",
  },
  {
    imagesList: [
      'maps/china_specific_south_up_detailed_português_(portugal).jpg',
      'maps/china_specific_south_up_detailed_português_(portugal).png',
      'maps/china_specific_south_up_detailed_português_(portugal).svg',
    ],
    imagePath: 'maps/china_specific_south_up_detailed_português_(portugal).png',
    fileName: 'china_specific_south_up_detailed_português_(portugal)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/china_specific_south_up_detailed_español_(españa).jpg',
      'maps/china_specific_south_up_detailed_español_(españa).png',
      'maps/china_specific_south_up_detailed_español_(españa).svg',
    ],
    imagePath: 'maps/china_specific_south_up_detailed_español_(españa).png',
    fileName: 'china_specific_south_up_detailed_español_(españa)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/china_specific_south_up_simple_english_(us).jpg',
      'maps/china_specific_south_up_simple_english_(us).png',
      'maps/china_specific_south_up_simple_english_(us).svg',
    ],
    imagePath: 'maps/china_specific_south_up_simple_english_(us).png',
    fileName: 'china_specific_south_up_simple_english_(us)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/china_specific_south_up_simple_简体中文.jpg',
      'maps/china_specific_south_up_simple_简体中文.png',
      'maps/china_specific_south_up_simple_简体中文.svg',
    ],
    imagePath: 'maps/china_specific_south_up_simple_简体中文.png',
    fileName: 'china_specific_south_up_simple_简体中文',
    content:
      "",
  },
  {
    imagesList: [
      'maps/china_specific_south_up_simple_português_(portugal).jpg',
      'maps/china_specific_south_up_simple_português_(portugal).png',
      'maps/china_specific_south_up_simple_português_(portugal).svg',
    ],
    imagePath: 'maps/china_specific_south_up_simple_português_(portugal).png',
    fileName: 'china_specific_south_up_simple_português_(portugal)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/china_specific_south_up_simple_español_(españa).jpg',
      'maps/china_specific_south_up_simple_español_(españa).png',
      'maps/china_specific_south_up_simple_español_(españa).svg',
    ],
    imagePath: 'maps/china_specific_south_up_simple_español_(españa).png',
    fileName: 'china_specific_south_up_simple_español_(españa)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/global_south_up_detailed_english_(us).jpg',
      'maps/global_south_up_detailed_english_(us).png',
      'maps/global_south_up_detailed_english_(us).svg',
    ],
    imagePath: 'maps/global_south_up_detailed_english_(us).png',
    fileName: 'global_south_up_detailed_english_(us)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/global_south_up_detailed_简体中文.jpg',
      'maps/global_south_up_detailed_简体中文.png',
      'maps/global_south_up_detailed_简体中文.svg',
    ],
    imagePath: 'maps/global_south_up_detailed_简体中文.png',
    fileName: 'global_south_up_detailed_简体中文',
    content:
      "",
  },
  {
    imagesList: [
      'maps/global_south_up_detailed_português_(portugal).jpg',
      'maps/global_south_up_detailed_português_(portugal).png',
      'maps/global_south_up_detailed_português_(portugal).svg',
    ],
    imagePath: 'maps/global_south_up_detailed_português_(portugal).png',
    fileName: 'global_south_up_detailed_português_(portugal)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/global_south_up_detailed_español_(españa).jpg',
      'maps/global_south_up_detailed_español_(españa).png',
      'maps/global_south_up_detailed_español_(españa).svg',
    ],
    imagePath: 'maps/global_south_up_detailed_español_(españa).png',
    fileName: 'global_south_up_detailed_español_(españa)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/global_south_up_simple_english_(us).jpg',
      'maps/global_south_up_simple_english_(us).png',
      'maps/global_south_up_simple_english_(us).svg',
    ],
    imagePath: 'maps/global_south_up_simple_english_(us).png',
    fileName: 'global_south_up_simple_english_(us)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/global_south_up_simple_简体中文.jpg',
      'maps/global_south_up_simple_简体中文.png',
      'maps/global_south_up_simple_简体中文.svg',
    ],
    imagePath: 'maps/global_south_up_simple_简体中文.png',
    fileName: 'global_south_up_simple_简体中文',
    content:
      "",
  },
  {
    imagesList: [
      'maps/global_south_up_simple_português_(portugal).jpg',
      'maps/global_south_up_simple_português_(portugal).png',
      'maps/global_south_up_simple_português_(portugal).svg',
    ],
    imagePath: 'maps/global_south_up_simple_português_(portugal).png',
    fileName: 'global_south_up_simple_português_(portugal)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/global_south_up_simple_español_(españa).jpg',
      'maps/global_south_up_simple_español_(españa).png',
      'maps/global_south_up_simple_español_(españa).svg',
    ],
    imagePath: 'maps/global_south_up_simple_español_(españa).png',
    fileName: 'global_south_up_simple_español_(españa)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/india_specific_south_up_detailed_english_(us).jpg',
      'maps/india_specific_south_up_detailed_english_(us).png',
      'maps/india_specific_south_up_detailed_english_(us).svg',
    ],
    imagePath: 'maps/india_specific_south_up_detailed_english_(us).png',
    fileName: 'india_specific_south_up_detailed_english_(us)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/india_specific_south_up_detailed_简体中文.jpg',
      'maps/india_specific_south_up_detailed_简体中文.png',
      'maps/india_specific_south_up_detailed_简体中文.svg',
    ],
    imagePath: 'maps/india_specific_south_up_detailed_简体中文.png',
    fileName: 'india_specific_south_up_detailed_简体中文',
    content:
      "",
  },
  {
    imagesList: [
      'maps/india_specific_south_up_detailed_português_(portugal).jpg',
      'maps/india_specific_south_up_detailed_português_(portugal).png',
      'maps/india_specific_south_up_detailed_português_(portugal).svg',
    ],
    imagePath: 'maps/india_specific_south_up_detailed_português_(portugal).png',
    fileName: 'india_specific_south_up_detailed_português_(portugal)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/india_specific_south_up_detailed_español_(españa).jpg',
      'maps/india_specific_south_up_detailed_español_(españa).png',
      'maps/india_specific_south_up_detailed_español_(españa).svg',
    ],
    imagePath: 'maps/india_specific_south_up_detailed_español_(españa).png',
    fileName: 'india_specific_south_up_detailed_español_(españa)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/india_specific_south_up_simple_english_(us).jpg',
      'maps/india_specific_south_up_simple_english_(us).png',
      'maps/india_specific_south_up_simple_english_(us).svg',
    ],
    imagePath: 'maps/india_specific_south_up_simple_english_(us).png',
    fileName: 'india_specific_south_up_simple_english_(us)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/india_specific_south_up_simple_简体中文.jpg',
      'maps/india_specific_south_up_simple_简体中文.png',
      'maps/india_specific_south_up_simple_简体中文.svg',
    ],
    imagePath: 'maps/india_specific_south_up_simple_简体中文.png',
    fileName: 'india_specific_south_up_simple_简体中文',
    content:
      "",
  },
  {
    imagesList: [
      'maps/india_specific_south_up_simple_português_(portugal).jpg',
      'maps/india_specific_south_up_simple_português_(portugal).png',
      'maps/india_specific_south_up_simple_português_(portugal).svg',
    ],
    imagePath: 'maps/india_specific_south_up_simple_português_(portugal).png',
    fileName: 'india_specific_south_up_simple_português_(portugal)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/india_specific_south_up_simple_español_(españa).jpg',
      'maps/india_specific_south_up_simple_español_(españa).png',
      'maps/india_specific_south_up_simple_español_(españa).svg',
    ],
    imagePath: 'maps/india_specific_south_up_simple_español_(españa).png',
    fileName: 'india_specific_south_up_simple_español_(españa)',
    content:
      "",
  },

  {
    imagesList: [
      'maps/global_north_up_simple_english_(us).jpg',
      'maps/global_north_up_simple_english_(us).png',
      'maps/global_north_up_simple_english_(us).svg',
    ],
    imagePath: 'maps/global_north_up_simple_english_(us).png',
    fileName: 'global_north_up_simple_english_(us)',
    content:
      "",
  },

  {
    imagesList: [
      'maps/global_north_up_detailed_english_(us).jpg',
      'maps/global_north_up_detailed_english_(us).png',
      'maps/global_north_up_detailed_english_(us).svg',
    ],
    imagePath: 'maps/global_north_up_detailed_english_(us).png',
    fileName: 'global_north_up_detailed_english_(us)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/global_north_up_simple_简体中文.jpg',
      'maps/global_north_up_simple_简体中文.png',
      'maps/global_north_up_simple_简体中文.svg',
    ],
    imagePath: 'maps/global_north_up_simple_简体中文.png',
    fileName: 'global_north_up_simple_简体中文',
    content:
      "",
  },
  {
    imagesList: [
      'maps/global_north_up_detailed_简体中文.jpg',
      'maps/global_north_up_detailed_简体中文.png',
      'maps/global_north_up_detailed_简体中文.svg',
    ],
    imagePath: 'maps/global_north_up_detailed_简体中文.png',
    fileName: 'global_north_up_detailed_简体中文',
    content:
      "",
  },
  {
    imagesList: [
      'maps/global_north_up_detailed_português_(portugal).jpg',
      'maps/global_north_up_detailed_português_(portugal).png',
      'maps/global_north_up_detailed_português_(portugal).svg',
    ],
    imagePath: 'maps/global_north_up_detailed_português_(portugal).png',
    fileName: 'global_north_up_detailed_português_(portugal)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/global_north_up_detailed_español_(españa).jpg',
      'maps/global_north_up_detailed_español_(españa).png',
      'maps/global_north_up_detailed_español_(españa).svg',
    ],
    imagePath: 'maps/global_north_up_detailed_español_(españa).png',
    fileName: 'global_north_up_detailed_español_(españa)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/global_north_up_simple_português_(portugal).jpg',
      'maps/global_north_up_simple_português_(portugal).png',
      'maps/global_north_up_simple_português_(portugal).svg',
    ],
    imagePath: 'maps/global_north_up_simple_português_(portugal).png',
    fileName: 'global_north_up_simple_português_(portugal)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/global_north_up_simple_español_(españa).jpg',
      'maps/global_north_up_simple_español_(españa).png',
      'maps/global_north_up_simple_español_(españa).svg',
    ],
    imagePath: 'maps/global_north_up_simple_español_(españa).png',
    fileName: 'global_north_up_simple_español_(españa)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/india_specific_north_up_detailed_english_(us).jpg',
      'maps/india_specific_north_up_detailed_english_(us).png',
      'maps/india_specific_north_up_detailed_english_(us).svg',
    ],
    imagePath: 'maps/india_specific_north_up_detailed_english_(us).png',
    fileName: 'india_specific_north_up_detailed_english_(us)',
    content:
      "",
  },
  {
    imagesList: [
      'maps/india_specific_north_up_simple_english_(us).jpg',
      'maps/india_specific_north_up_simple_english_(us).png',
      'maps/india_specific_north_up_simple_english_(us).svg',
    ],
    imagePath: 'maps/india_specific_north_up_simple_english_(us).png',
    fileName: 'india_specific_north_up_simple_english_(us)',
    content:
      "",
  },

  {
    imagesList: [
      'maps/china_specific_north_up_detailed_简体中文.jpg',
      'maps/china_specific_north_up_detailed_简体中文.png',
      'maps/china_specific_north_up_detailed_简体中文.svg',
    ],
    imagePath: 'maps/china_specific_north_up_detailed_简体中文.png',
    fileName: 'china_specific_north_up_detailed_简体中文',
    content:
      "",
  },
  {
    imagesList: [
      'maps/china_specific_north_up_simple_简体中文.jpg',
      'maps/china_specific_north_up_simple_简体中文.png',
      'maps/china_specific_north_up_simple_简体中文.svg',
    ],
    imagePath: 'maps/china_specific_north_up_simple_简体中文.png',
    fileName: 'china_specific_north_up_simple_简体中文',
    content:
      "",
  },
];

const regionData = ['Global', 'India Specific', 'China Specific'];
const orientation = ['South UP', 'North UP'];
const view = ['Simple', 'Detailed'];
const lang = [
  {
    image: englishImage,
    name: 'English (US)',
  },
  {
    image: chinaImage,
    name: '简体中文',
  },
  {
    image: portugeseImage,
    name: 'Português (Portugal)',
  },
  {
    image: espanoImage,
    name: 'Español (España)',
  },
];

const MapList = () => {
  const [region, setRegion] = useState('');
  const [orientationState, setOrientationState] = useState('');
  const [viewState, setViewState] = useState('');
  const [language, setLanguage] = useState('');
  const queryParams = new URLSearchParams();

  const theme = useTheme();
  // const history = useHistory();

  const proceedToMap = () => {
    let output = `${region.toLowerCase().replace(' ', '_')}_${orientationState
      .toLowerCase()
      .replace(' ', '_')}_${viewState
      .toLowerCase()
      .replace(' ', '_')}_${language.toLowerCase().replace(' ', '_')}`;
    // console.log(output);
    const x = data.filter((i) => i.fileName == output);
    console.log(x, output);
    localStorage.setItem('ImagesMaps', JSON.stringify(x?.[0]?.imagesList));
    queryParams.set('imagePath', encodeURIComponent(x?.[0]?.imagePath));
    queryParams.set('content', encodeURIComponent(x?.[0]?.content));

    const queryString = queryParams.toString();
    navigate(`/MapNew?${queryString}`, {
      state: { content: x?.[0]?.content, imagePath: x?.[0]?.imagePath },
    });

    output = '';
  };
  return (
    <Layout fullWidth={true}>
      <section className="sub-header">
        <h1>Explore Our Maps</h1>
        <h2>
          Based on <span>Hobo-Dyer</span> Projection
        </h2>
      </section>
      <div className="content">
        <div className="wrapper">
          <div className="content-header">
            <h3>STEP 1 : Choose a Boundary</h3>
          </div>
          <div className="content-content step1">
            {regionData.map((item) => (
              <button
                className={`btn ${region === item ? 'selected' : ''}`}
                id="global"
                onClick={() => setRegion(item)}
              >
                {item}
              </button>
            ))}
          </div>
        </div>
        <svg
          width="22"
          height="19"
          viewBox="0 0 22 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_1_5)">
            <path
              d="M13.6667 5.33333H3V9.66667H13.6883V14L19 7.5L13.6883 1L13.6667 5.33333Z"
              fill="black"
              fill-opacity="0.7"
              shape-rendering="crispEdges"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_1_5"
              x="0"
              y="0"
              width="22"
              height="19"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="1.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_5"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_5"
                result="shape"
              />
            </filter>
          </defs>
        </svg>

        <div className="wrapper">
          <div className="content-header">
            <h3>STEP 2 : Choose an Orientation</h3>
          </div>
          <div className="content-content step2">
            {orientation?.map((item) => (
              <button
                disabled={!region}
                className={`btn btnstep2 ${
                  orientationState === item ? 'selected' : ''
                }`}
                id={item}
                onClick={() => setOrientationState(item)}
              >
                {item}
              </button>
            ))}

            <div className="content-header-h">
              <h3>Select a View</h3>
            </div>
            <div className="content-content-sub step3">
              {view?.map((item) => (
                <button
                  disabled={!orientationState}
                  id={item}
                  style={{ borderRadius: '6px' }}
                  className={`btn btn-noshadow ${
                    viewState === item ? 'selected' : ''
                  }`}
                  onClick={() => setViewState(item)}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>
        </div>
        <svg
          width="22"
          height="19"
          viewBox="0 0 22 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_1_5)">
            <path
              d="M13.6667 5.33333H3V9.66667H13.6883V14L19 7.5L13.6883 1L13.6667 5.33333Z"
              fill="black"
              fill-opacity="0.7"
              shape-rendering="crispEdges"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_1_5"
              x="0"
              y="0"
              width="22"
              height="19"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="2" />
              <feGaussianBlur stdDeviation="1.5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1_5"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1_5"
                result="shape"
              />
            </filter>
          </defs>
        </svg>

        <div className="wrapper">
          <div className="content-header">
            <h3>STEP 3 : Choose a Language</h3>
          </div>
          <div className="content-content step4">
            {lang?.map((item) => (
              <button
                disabled={!viewState}
                className={`btn btn-noshadow ${
                  language === item.name ? 'selected' : ''
                }`}
                style={{ width: '260px' }}
                id="english"
                onClick={() => setLanguage(item.name)}
              >
                <img src={item.image} /> {item.name}
              </button>
            ))}
          </div>
        </div>
        <div className="content-content-bottom" style={{ marginTop: '-40px' }}>
          <button
            className="btn btn-grey"
            id="reset"
            disabled={!region}
            onClick={() => {
              setRegion('');
              setOrientationState('');
              setLanguage('');
              setViewState('');
            }}
          >
            Reset
          </button>
          <button
            onClick={() => proceedToMap()}
            className={`btn btn-grey ${
              language && viewState && region && orientationState
                ? 'selected'
                : ''
            }`}
            id="proceed"
            style={{ background: '#005ba', color: 'white' }}
          >
            Proceed to Map
          </button>
        </div>
      </div>
    </Layout>
  );
};
export default MapList;
